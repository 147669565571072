import './App.css';
import SimpleLineChart from './SimpleLineChart';

const App = () => {
  return (
    <div>
      <SimpleLineChart />
    </div>
  );
}

export default App;
